import { UserRole } from '@/constants';
import { Button, Col, Divider, message, Row } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';

const styles = {
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 40,
    padding: '8px 12px',
    gap: 12,
    borderRadius: 8,
  },
  divider: {
    margin: '36px 0',
    borderColor: '#D9DEE5',
  },
} as Record<string, React.CSSProperties>;

const ThirdPartyIcons = {
  Google: (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M22.56 12.25C22.56 11.47 22.49 10.72 22.36 10H12V14.255H17.92C17.665 15.63 16.89 16.795 15.725 17.575V20.335H19.28C21.36 18.42 22.56 15.6 22.56 12.25Z"
        fill="#4285F4"
      />
      <path
        d="M11.9999 23.0001C14.9699 23.0001 17.4599 22.0151 19.2799 20.3351L15.7249 17.5751C14.7399 18.2351 13.4799 18.6251 11.9999 18.6251C9.13492 18.6251 6.70992 16.6901 5.84492 14.0901H2.16992V16.9401C3.97992 20.5351 7.69992 23.0001 11.9999 23.0001Z"
        fill="#34A853"
      />
      <path
        d="M5.845 14.0901C5.625 13.4301 5.5 12.7251 5.5 12.0001C5.5 11.2751 5.625 10.5701 5.845 9.91006V7.06006H2.17C1.4 8.59292 0.999321 10.2847 1 12.0001C1 13.7751 1.425 15.4551 2.17 16.9401L5.845 14.0901Z"
        fill="#FBBC05"
      />
      <path
        d="M11.9999 5.375C13.6149 5.375 15.0649 5.93 16.2049 7.02L19.3599 3.865C17.4549 2.09 14.9649 1 11.9999 1C7.69992 1 3.97992 3.465 2.16992 7.06L5.84492 9.91C6.70992 7.31 9.13492 5.375 11.9999 5.375Z"
        fill="#EA4335"
      />
    </svg>
  ),
  Facebook: (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 22 23" fill="none">
      <path
        d="M9.35953 21.4939L9.9375 21.5851V21V13.9694V13.4694H9.4375H7.39844V11.5611H9.4375H9.9375V11.0611V8.84452C9.9375 7.68267 10.2795 6.84267 10.8243 6.29404C11.3692 5.74529 12.1744 5.43012 13.2146 5.43012C13.7349 5.43012 14.2735 5.47706 14.6865 5.52503C14.7835 5.5363 14.873 5.54756 14.9531 5.55819V7.10261H14.1922C13.4614 7.10261 12.9007 7.33532 12.5323 7.76395C12.176 8.17853 12.0625 8.70402 12.0625 9.17387V11.0611V11.5611H12.5625H14.7539L14.463 13.4694H12.5625H12.0625V13.9694V21V21.5851L12.6405 21.4939C17.6625 20.701 21.5 16.3306 21.5 11.0611C21.5 5.23128 16.8018 0.5 11 0.5C5.19818 0.5 0.5 5.2312 0.5 11.0611C0.5 16.3305 4.33746 20.701 9.35953 21.4939Z"
        fill="#1877F2"
        stroke="#1877F2"
      />
      <path
        d="M14.8926 13.8906L15.3359 11H12.5625V9.12422C12.5625 8.33328 12.9499 7.5625 14.1922 7.5625H15.4531V5.10156C15.4531 5.10156 14.3087 4.90625 13.2146 4.90625C10.9305 4.90625 9.4375 6.29063 9.4375 8.79688V11H6.89844V13.8906H9.4375V22.0352C9.96387 22.1562 10.4719 22.2462 10.9951 22.2461C11.5183 22.2463 12.0456 22.1162 12.5625 22.0352V13.8906H14.8926Z"
        fill="white"
      />
    </svg>
  ),
};

// 支持第三方授权的平台
export type SupportThirdPartyAuthPlatform = keyof typeof ThirdPartyIcons;

export interface ThirdPartyAuthInfo {
  userId: number;
  email: string;
  userType: UserRole | 0; // 通过第三方登录，新注册的用户userType = 0
}

const isWebView = () => {
  const useragent = navigator.userAgent;
  const rules = ['WebView', '(iPhone|iPod|iPad)(?!.*Safari/)', 'Android.*(wv|.0.0.0)'];
  const regex = new RegExp(`(${rules.join('|')})`, 'ig');
  return Boolean(useragent.match(regex));
};

const ThirdPartyAuthButtons = ({
  handleMessage,
}: {
  handleMessage: (authInfo: ThirdPartyAuthInfo, platform: SupportThirdPartyAuthPlatform) => void;
}) => {
  const { formatMessage } = useIntl();

  const handleThirdPartyAuth = async (platform: SupportThirdPartyAuthPlatform) => {
    if (isWebView()) {
      return message.error(formatMessage({ id: 'thirdPartyAuth.openInBrowser', defaultMessage: '请在浏览器中打开' }));
    }

    window.open(
      `${window.location.origin}/thirdPartyAuth?platform=${platform}&type=1`,
      'newwindow',
      'height=500, width=500, top=0, right=0, no-store, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=no, status=no',
    );

    const _handleMessage = ({ data }: MessageEvent) => {
      // LOG: 所有 message 传递的数据
      console.log(data);

      if (data.source && data.source === 'youdao-ads') {
        // LOG: 第三方授权返回的数据
        window.onmessage = null;
        const authInfo = JSON.parse(data.authInfo ?? '{}');
        handleMessage(authInfo, platform);
      }
    };

    window.onmessage = _handleMessage;
  };

  return (
    <>
      <Row justify="space-between" gutter={[36, 24]} style={{ marginTop: 36 }}>
        <Col span={24} xl={12}>
          <Button block style={styles.button} onClick={() => handleThirdPartyAuth('Google')}>
            {ThirdPartyIcons.Google}
            <FormattedMessage id="thirdPartyAuth.continueWithGoogle" defaultMessage="使用Google账号" />
          </Button>
        </Col>
        <Col span={24} xl={12}>
          <Button block style={styles.button} onClick={() => handleThirdPartyAuth('Facebook')}>
            {ThirdPartyIcons.Facebook}
            <FormattedMessage id="thirdPartyAuth.continueWithFacebook" defaultMessage="使用Facebook账号" />
          </Button>
        </Col>
      </Row>
      <Divider style={styles.divider} plain>
        OR
      </Divider>
    </>
  );
};

export default ThirdPartyAuthButtons;
