import useGA from '@/hooks/useGa';
import { GetServerSideProps } from 'next';
import PageCom from '@/page-components/login/Login';
import { MyAES } from '@/utils/aes';
import { INVITE_COOPERATION_EMAIL, INVITE_COOPERATION_ID, INVITE_KOL_EMAIL, INVITE_KOL_ID } from '@/page-components/invite/constants';

export const comeFrom = ({
  kol_id,
  kol_email,
  f,
  p,
}: {
  kol_id?: string;
  kol_email?: string;
  /**
   * f: from type, ivth: koc 邀请页, hp: 官网
   */
  f?: string;
  /**
   * p: page
   */
  p?: string;
}) => {
  if (f === 'ivth') {
    // h5
    if (p === 'invite_offers') return '/invite/offers';
    else if (p === 'invite_kol') return '/invite/kol'; // 邀请宣传页，已废弃，由 /invite/offers 替代
    else if (sessionStorage.getItem(INVITE_COOPERATION_ID)) return '/invite'; // 邀请合作页
  } else if (f === 'hp') {
    // 官网
    if (!p) return 'homepage_header'; // header
    else if (p === 'home_join-now') return '/home#join-now-btn'; // 首屏 join now 按钮
    else if (p === 'home_work') return '/home#how-dose-work'; // work 模块
    else if (p === 'join_now') return 'homepage_join_now_module'; // join now 模块
    else if (p === 'offers') return '/offers'; // offers 页面，报名弹窗内的超链
  } else if (kol_id && kol_email) {
    return 'email'; // 邮件邀请
  }

  return '';
};

const Login: React.FC<{ query: Record<string, any> }> = ({ query }) => {
  const { utm_ydk: kol_id, utm_yde: kol_email, f, p } = query;
  const from = comeFrom({ kol_id, kol_email, f, p });
  useGA({
    pageTitle: '登录页',
    params: {
      from,
      kol_id: sessionStorage.getItem(INVITE_COOPERATION_ID) || sessionStorage.getItem(INVITE_KOL_ID) || (kol_id && MyAES.decrypt(String(kol_id))),
      kol_email:
        sessionStorage.getItem(INVITE_COOPERATION_EMAIL) ||
        sessionStorage.getItem(INVITE_KOL_EMAIL) ||
        (kol_email && MyAES.decrypt(String(kol_email))),
    },
  });
  return <PageCom query={query} />;
};
export const getServerSideProps: GetServerSideProps = async ({ query }) => {
  return { props: { query } };
};
export default Login;
